exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-hungry-new-world-hungry-new-world-mdx": () => import("./../../../src/pages/books/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/books/hungry-new-world/hungry-new-world.mdx" /* webpackChunkName: "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-hungry-new-world-hungry-new-world-mdx" */),
  "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-exile-iv-exile-mdx": () => import("./../../../src/pages/books/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/books/iv-exile/iv-exile.mdx" /* webpackChunkName: "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-exile-iv-exile-mdx" */),
  "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-heretic-iv-heretic-mdx": () => import("./../../../src/pages/books/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/books/iv-heretic/iv-heretic.mdx" /* webpackChunkName: "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-heretic-iv-heretic-mdx" */),
  "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-mendicant-iv-mendicant-mdx": () => import("./../../../src/pages/books/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/books/iv-mendicant/iv-mendicant.mdx" /* webpackChunkName: "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-mendicant-iv-mendicant-mdx" */),
  "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-outlander-iv-outlander-mdx": () => import("./../../../src/pages/books/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/books/iv-outlander/iv-outlander.mdx" /* webpackChunkName: "component---src-pages-books-mdx-frontmatter-slug-js-content-file-path-src-books-iv-outlander-iv-outlander-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-sub-thanks-js": () => import("./../../../src/pages/sub-thanks.js" /* webpackChunkName: "component---src-pages-sub-thanks-js" */)
}

